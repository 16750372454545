import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';

const NotFound = ({ location }) => (
  <Layout location={location.pathname}>
    <Flex
      alignItems="center"
      bg="primary"
      color="white"
      direction="column"
      justify="center"
      minH="100vh"
      p={5}
      pos="absolute"
      top="0"
      w="100vw"
      zIndex="hide"
    >
      <StaticImage src="../assets/404.png" alt="404" />
      <Heading as="h2">Página não encontrada</Heading>
      <Text>Utilize a barra superior para navegar pelo site</Text>
    </Flex>
  </Layout>
);

NotFound.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default NotFound;
